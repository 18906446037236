import CenterLayout from "@components/centerlayout"
import Box from "@ui/Box"
import Text from "@ui/Text/Text"
import { rem } from "polished"
import React from "react"

import { DividerIllustration } from "../components/DividerIllustration"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Parallax, { ImgTitle, ImgCopyright } from "@components/parallax"
import { graphql } from "gatsby"
import { IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import Flowchart from "@components/flowchart"
import { AppLink } from "@ui/Link/Link"
import List from "@ui/List/List"
import ListItem from "@ui/List/ListItem"
import { css } from "@emotion/react"

interface Props {
  data: {
    leaveNoOneBehind: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

const HowToPage = ({ data }: Props) => (
  <Layout>
    <SEO title="How to use this Guide" />
    <DividerIllustration loading="eager" />
    <CenterLayout my="5xl">
      <Box maxWidth="80ch">
        <Text size="md" as="h1" fontWeight="bold" mb="lg">
          How to use this Guide
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" mb="lg">
          Step-by-step guidance
        </Text>
        <Text as="p" mb="md">
          This Guide has a modular structure that follows a step-by-step path
          from pre-planning preparations to the formulation of the National
          Development Plan (NDP) including its strategic and operational
          components. The instructions and tools provided are not prescriptive:
          they are intended as a menu of options that planners can select
          according to national circumstances.
        </Text>
        <Flowchart my="4xl" />
        <Text size="2xl" as="h2" fontWeight="bold" mb="lg">
          Below is an overview of the modules and their contents:
        </Text>
        <AppLink to="/modules/preparation/introduction">
          Module 1 - Preparatory Phase: ‘How do we get ready?’
        </AppLink>
        <Text as="p" mb="lg">
          The preparatory phase comprises of preliminary steps that need to be
          taken to start an integrated planning process that considers the
          referenced Agendas and frameworks. This phase supports planners as
          they design the planning structure and process, to ensure an
          all-of-government and all-of-society approach, in line with the LNOB
          principle as well as peace and justice considerations.
        </Text>
        <AppLink to="/modules/vision/introduction">
          Module 2 - National Development Vision: ‘The World We Want’
        </AppLink>
        <Text as="p" mb="lg">
          The national development vision is a long-term plan that articulates
          the country’s aspirations and guides efforts toward the achievement of
          development goals. This module offers planners guidance on collecting
          and distilling information to formulate the national development
          vision while incorporating the SDGs, Agenda 2063, as well as the goals
          of the Paris Agreement, the Sendai Framework for Disaster Risk
          Reduction, the LNOB Principle, gender and peace and justice
          considerations.
        </Text>
        <AppLink to="/modules/context-analysis/introduction">
          Module 3 - Integrated Context Analysis: ‘The World We Have’
        </AppLink>
        <Text as="p" mb="lg">
          The purpose of this module is to support planners in gathering data to
          see where they are in their development trajectory, and how they got
          there. This module describes how to carry out an integrated context
          analysis as a baseline for the NDP, based on the
          principles of alignment and integration. While alignment means linking
          the country’s goals, targets and indicators to the ones encapsulated
          in the SDGs, Agenda 2063,  as well as  international commitments to
          which the country is party, integration refers to policy integration
          across sectors and themes, so that policies can build on synergies and
          take into account the possible trade-offs associated with the agenda
          goals and targets in the NDP.
        </Text>
        <AppLink to="/modules/future-analysis/introduction">
          Module 4 - Integrated Future Analysis: ‘The Worlds We Get’
        </AppLink>
        <Text as="p" mb="lg">
          This module presents possible pathways to development based on how
          various futures scenarios are constructed building on the vision as
          well as the empirical data collected in the previous modules. Building
          on the integrated context analysis, the integrated future analysis is
          based on processes of anticipation that identify opportunities and
          threats that may arise in mid- to long-term versions of the future. It
          draws on different combinations of assumptions, data and trends to
          explore multiple scenarios or futures, including policy trade-offs,
          with a view to inform decision-making.
        </Text>
        <AppLink to="/modules/strategic-plan/introduction">
          Module 5- Strategic Plan: ‘Towards the World We Want’  
        </AppLink>
        <Text as="p" mb="lg">
          Based on the outputs of modules 2, 3, and 4, this module offers
          guidance to planners in formulating the country’s theory of change,
          strategic goals, development outcomes and the strategies required to
          achieve the national development vision, buttressed on a robust theory
          of change and a comprehensive analysis of available / expected
          resources. A template is provided to support planners as they link
          their targets to the SDGs, Agenda 2063, the Paris Agreement, the
          Sendai Framework, the LNOB principle, gender and peace and justice
          considerations.
        </Text>
        <AppLink to="/modules/operational-plan/introduction">
          Module 6 – Operational Plan: ‘Realising the World We Want’
        </AppLink>
        <Text as="p" mb="lg">
          This module helps planners flesh out how the Strategic Plan will be
          implemented in practice. The module provides an interactive template
          where planners can indicate the policies, programmes and key
          performance indicators they will use to achieve the strategic goals,
          development outcomes and Strategies identified in the previous module,
          building on SDG and Agenda 2063 indicators.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" mt="4xl" mb="xl">
          Thematic tabs
        </Text>
        <Text as="p" mb="lg">
          In addition to step-by-step instructions, each module has five tabs,
          located on the top.
        </Text>
        <List>
          <ListItem>Climate and Environment </ListItem>
          <ListItem>Disaster Risk Reduction </ListItem>
          <ListItem>Leave No One Behind </ListItem>
          <ListItem>Gender </ListItem>
          <ListItem>Peace and Justice</ListItem>
        </List>
        <Text as="p" mb="lg">
          The tabs provide links to the international commitments as well as
          additional guidance on how to mainstream the NDCs, the Sendai
          Framework and the LNOB Principle as well as gender and peace and
          justice considerations in the planning process, as planners follow the
          steps in the modules.
        </Text>
        <StaticImage
          width={800}
          transformOptions={{ fit: "contain" }}
          layout="constrained"
          src="../images/tabs_example.png"
          alt="Tabs example"
          css={css`
            box-shadow: 0 7px 10px rgb(0 0 0 / 8%), 0 2px 3px rgb(0 0 0 / 10%);
          `}
        />

        <Text size="2xl" as="h2" fontWeight="bold" mt="4xl" mb="lg">
          Tools, Templates and Examples
        </Text>
        <Text as="p" mb="lg">
          A selection of recommended tools is listed in a separate tab under
          each module. Where tools to support a specific step are not available,
          a template is provided. Links to country examples are provided to help
          planners visualize outputs under each module.
        </Text>
        <Text size="2xl" as="h2" fontWeight="bold" mt="2xl" mb="lg">
          A living document
        </Text>
        <Text as="p" mb="lg">
          The Integrated Planning Guide in Africa to mainstream the Sustainable
          Development Goals (SDGs), AU Agenda 2063, Nationally Determined
          Contributions (NDCs) and Disaster Risk Reduction (DRR) is a living
          document and will be continuously updated. Additional modules covering
          monitoring and evaluation, budgeting and alignment with local plans
          are forthcoming.
        </Text>
      </Box>
    </CenterLayout>
    <Parallax
      scaleFactor={1.25}
      backgroundProps={{
        image: data.leaveNoOneBehind.childImageSharp.gatsbyImageData,
        alt: "Plans that leave no one behind",
      }}
    >
      <CenterLayout py={rem("224px")}>
        <ImgTitle>Plans that leave no one behind</ImgTitle>
        <ImgCopyright>
          Global Rural Electrification Program, Morocco
        </ImgCopyright>
      </CenterLayout>
    </Parallax>
  </Layout>
)

export const pageQuery = graphql`
  {
    leaveNoOneBehind: file(relativePath: { eq: "leave-no-one-behind.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default HowToPage
